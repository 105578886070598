<template>
  <div v-if="dealer" class="world lead-page">
    <div class="wrapper-title">
      <div class="container">
        <router-link :to="{name: 'ww.leads'}" class="btn-previous">&larr; {{ $t('Back to overview') }}</router-link>
        <div class="flex">
          <h1 v-if="lead">{{ lead.first_name }} {{ lead.last_name }}</h1>
          <h1 v-else>{{ lead.first_name }} {{ lead.last_name }}</h1>
          <div class="buttons status-buttons">
            <button v-for="status in statuses" :key="status.id" :class="'status-' + status.status + (lead.status && status.status === lead.status.status ? ' active' : '')" class="status" @click="saveLeadStatus(status.id)">
              {{ $t('leads_status_' + status.status) }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper-content">
      <div class="container">
        <div class="content-navigation">
          <ul class="navigation">
            <li :class="activeView === 'info' ? 'active' : ''" @click="activeView = 'info'">{{ $t('Gegevens') }}</li>
            <li v-if="leadId" :class="activeView === 'comments' ? 'active' : ''" @click="activeView = 'comments'">{{ $t('Opmerkingen') }}</li>
<!--            <li v-if="leadId" :class="activeView === 'quotes' ? 'active' : ''" @click="activeView = 'quotes'">{{ $t('Offertes') }}</li>-->
          </ul>

          <div v-if="activeView === 'quotes'" class="buttons">
            <div></div>
            <button class="btn" @click="createQuote">{{ $t('Offerte aanmaken') }}</button>
          </div>
        </div>

        <div class="views">
          <div v-show="activeView === 'info'" class="view">
            <formulate-form ref="leadForm" v-model="lead" name="leadForm">
              <div class="data-wrapper">
                <div class="data-title">{{ $t('Algemene gegevens') }}</div>
                <div class="data-content grid">
                  <formulate-input :label="$t('Voornaam')" name="first_name" validation="required"></formulate-input>
                  <formulate-input :label="$t('Naam')" name="last_name" validation="required"></formulate-input>
                  <formulate-input :label="$t('E-mailadres')" type="email" validation="optional|email" name="email"></formulate-input>
                  <formulate-input :label="$t('Telefoonnummer')" name="telephone"></formulate-input>
                  <formulate-input v-if="lead.status && lead.status.status === 'to_follow_up'" :label="$t('Op te volgen')" type="datetime-local" name="status_followup_date"></formulate-input>
                </div>
              </div>

              <div class="data-wrapper">
                <div class="data-title">{{ $t('Adres') }}</div>
                <div class="data-content grid">
                  <formulate-input :label="$t('Straat + nummer')" name="street_and_nr"></formulate-input>
                  <formulate-input :label="$t('Postcode')" name="zip"></formulate-input>
                  <formulate-input :label="$t('Gemeente')" name="city"></formulate-input>
                </div>
              </div>

              <div v-if="lead.comment_from_website" class="data-wrapper">
                <div class="data-title">{{ $t('Wil je nog iets kwijt?') }}</div>
                <div class="data-content">
                  {{ lead.comment_from_website }}
                </div>
              </div>

              <div v-if="lead.help_me_choose_result_page" class="data-wrapper">
                <div class="data-title">{{ $t('Help me kiezen') }}</div>
                <div class="data-content">
                  <a :href="lead.help_me_choose_result_page" class="btn" target="_blank">{{ $t('Bekijk resultaat') }}</a>
                </div>
              </div>

              <div v-if="lead.appointment" class="data-wrapper">
                <div class="data-title">{{ $t('Afspraak') }}</div>
                <div class="data-content">

                  <div class="appointment">
                    <div class="label-value-wrapper date">
                      <span class="label">Datum:</span>
                      <span class="value">{{ datetime.fromISO(lead.appointment.slot).setLocale('nl').toFormat('dd LLLL yyyy') }}</span>
                      <span><img alt="Calendar" src="@/assets/img/calendar_black.svg"></span>
                    </div>

                    <div class="label-value-wrapper time">
                      <span class="label">Uur:</span>
                      <span class="value">{{ datetime.fromISO(lead.appointment.slot).setLocale('nl').toFormat('T') }}</span>
                      <img alt="Clock" src="@/assets/img/clock.svg">
                    </div>

                    <div class="label-value-wrapper date">
                      <span class="label">Aangemaakt:</span>
                      <span class="value">{{ datetime.fromISO(lead.appointment.created).setLocale('nl').toFormat('dd LLLL yyyy HH:mm') }}</span>
                      <span><img alt="Calendar" src="@/assets/img/calendar_black.svg"></span>
                    </div>

                    <div class="grid">
                      <div class="label-value-wrapper">
                        <span class="label">Type project:</span>
                        <span class="value">{{ projectTypeOptions[lead.appointment.project_type] }}</span>
                      </div>
                      <div class="label-value-wrapper">
                        <span class="label">Op zoek naar:</span> <span class="value">
                    <span v-for="(label, i) in lead.appointment.searching_for" :key="i">
                        {{ searchingForOptions[label] }} {{ (lead.appointment.searching_for.length > (i + 1)) ? ',' : '' }}
                    </span>
                  </span>
                      </div>
                      <div class="label-value-wrapper">
                        <span class="label">Nieuwe ramen:</span>
                        <span class="value">{{ newWindowsOptions[lead.appointment.new_windows] }}</span>
                      </div>
                      <div class="label-value-wrapper">
                        <span class="label">Geschatte periode:</span>
                        <span class="value">{{ deliveryTermOptions[lead.appointment.delivery_term] }}</span>
                      </div>
                    </div>

                    <div class="label-value-wrapper extra">
                      <span class="label">Bijkomende opmerkingen of vragen:</span>
                      <span class="value">{{ lead.appointment.extra || '/' }}</span>
                    </div>

                    <div v-if="lead.appointment.files && lead.appointment.files.length > 0" class="label-value-wrapper">
                      <span class="label">Bouwplannen:</span>
                      <div v-for="file in lead.appointment.files" :key="file" class="value">
                        <a v-if="images[file]" :href="images[file].url" target="_blank">{{ images[file].name }}</a>
                      </div>
                    </div>

                    <div class="label-value-wrapper">
                      <span class="label">Spontaan:</span>
                      <span class="value">{{ lead.appointment.spontaneous ? $t('Ja') : $t('Nee') }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <button class="btn" @click="saveLead">{{ $t('Lead opslaan') }}</button>
            </formulate-form>
          </div>

          <div v-if="leadId" v-show="activeView === 'comments'" class="view">
            <div class="data-wrapper">
              <div class="data-title">{{ $t('Opmerkingen') }}</div>
              <div v-if="comments && comments.length > 0" class="data-content comments">
                <div v-for="comment in comments" :key="comment.id" class="comment">
                  <div class="comment-info">{{ comment.created_by.email }} - {{ datetime.fromISO(comment.created).setLocale(activeLocale).toFormat('dd/LL/yyyy HH:mm') }}</div>
                  <div class="comment-data">{{ comment.comment }}</div>
                </div>
              </div>

              <div class="data-content">
                <formulate-form ref="leadCommentForm" v-model="comment" name="leadCommentForm" @submit="saveLeadComment">
                  <formulate-input :label="$t('Opmerking')" name="comment"></formulate-input>
                  <formulate-input input-class="btn" type="submit">{{ $t('Opmerking toevoegen') }}</formulate-input>
                </formulate-form>
              </div>
            </div>
          </div>

          <div v-if="leadId" v-show="activeView === 'quotes'" class="view">
            <div class="data-content">
              <div class="quotes-wrapper">
                <div v-if="quotes.length > 0" class="table-wrapper">
                  <div class="table-element">
                    <table class="quotes">
                      <thead>
                      <tr>
                        <th>{{ $t('Nummer') }}</th>
                        <th>{{ $t('Prijs excl. btw') }}</th>
                        <th>{{ $t('Aangemaakt op') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <router-link v-for="quote in quotes" :key="'quote-' + quote.id" :to="{ name: 'ww.quotes.detail', params: { id: quote.id } }" class="quote" tag="tr">
                        <td>{{ quote.number }}</td>
                        <td>{{ $n(quote.calculated_total_without_vat, 'currency', 'nl-BE') }}</td>
                        <td>{{ datetime.fromISO(quote.created).setLocale(activeLocale).toFormat('dd/LL/yyyy HH:mm') }}</td>
                      </router-link>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-else>
                  {{ $t('Er zijn nog geen offertes') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { DateTime } from 'luxon'
import { notification } from '@/notifications'
import appointmentsData from '@/appointments-data'

export default {
  mixins: [appointmentsData],
  data () {
    return {
      activeView: 'info',
      leadId: null,
      lead: {
        type: 'manual',
        products: []
      },
      comment: {},
      comments: [],
      statuses: [],
      quotes: [],
      datetime: DateTime
    }
  },
  async mounted () {
    await this.setDealer()

    this.leadId = this.$route.params.id !== 'new' ? this.$route.params.id : null

    await ApiService.fetchLeadsStatuses().then(res => {
      this.statuses = res.data
    })

    if (this.leadId && this.leadId !== 'new') {
      await this.fetchLead()
      await ApiService.fetchLeadComments(this.dealer.id, this.leadId).then(res => {
        this.comments = res.data
      })

      await ApiService.fetchLeadQuotes(this.dealer.id, this.leadId).then(res => {
        this.quotes = res.data
      })
    }
  },
  methods: {
    async fetchLead () {
      const res = await ApiService.fetchDealerLead(this.dealer.id, this.leadId)
      this.lead = res.data

      const files = []

      if (this.lead.appointment && this.lead.appointment.files) {
        for (const id of this.lead.appointment.files) {
          files.push({ file_id: id })
        }
      }

      await this.$store.dispatch('fetchImages', { objects: files, value: 'file_id' })
    },
    async saveLead () {
      this.$formulate.submit('leadForm')
      if (await this.$refs.leadForm.hasValidationErrors()) {
        return
      }
      this.$formulate.resetValidation('leadForm')

      if (this.leadId) {
        await ApiService.patchDealerLead(this.dealer.id, this.leadId, this.lead).then(res => {
          if (res.status === 200) {
            this.lead = res.data
            notification(this.$t('Lead succesvol aangepast!'))
          }
        })
      } else {
        await ApiService.createDealerLead(this.dealer.id, this.lead).then(res => {
          this.$router.push({ name: 'ww.leads.detail', params: { id: res.data.id } })
          notification(this.$t('Lead succesvol toegevoegd!'))
        })
      }
    },
    async saveLeadComment () {
      await ApiService.createLeadComment(this.dealer.id, this.leadId, this.comment).then(res => {
        this.comments.push(res.data)
      })
    },
    async saveLeadStatus (statusId) {
      this.lead.status_id = statusId
      await this.saveLead()
    },
    async createQuote () {
      const quote = {
        dealer_id: this.dealer.id,
        lead_id: this.leadId,
        expires_on: DateTime.now().plus({ month: 1 }).toISODate(),
        address: {
          first_name: this.lead.first_name,
          last_name: this.lead.last_name,
          email: this.lead.email,
          telephone: this.lead.telephone,
          country_code: 'be', // todo country code on lead level
          locality: this.lead.city,
          postal_code: this.lead.zip,
          address1: this.lead.street_and_nr
        }
      }

      // await ApiService.createAddress(quoteAddress).then(addressResponse => {
      //   quote.address_id = addressResponse.data.id
      // })

      await ApiService.createQuote(quote).then(async res => {
        if (res.status === 200) {
          await this.$router.push({ name: 'ww.quotes.detail', params: { id: res.data.id } })
        }
      })
    }
  },
  computed: {
    images () {
      return this.$store.getters.getImages
    }
  }
}
</script>
