module.exports = {
  data () {
    return {
      visitAmountOptions: { alone: 'Alleen', together: 'Samen met één ander persoon' },
      projectTypeOptions: { new: 'Nieuwbouw', renovation: 'Renovatie' },
      searchingForOptions: {
        shutters: 'Rolluiken',
        sunprotection: 'Screens',
        outdoor: 'Zonneschermen of verandazonwering',
        none: 'Ik weet het niet'
      },
      newWindowsOptions: { yes: 'Ja', no: 'Nee' },
      deliveryTermOptions: {
        oneMonth: '< 1 maand',
        withinThreeMonths: 'Binnen een termijn van 1 tot 3 maanden',
        withinSixMonths: 'Binnen een termijn van 3 tot 6 maanden'
      }
    }
  }
}
